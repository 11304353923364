import Vue from 'vue'

import vueCustomElement from 'vue-custom-element'

const components = require('./exportedComponents.js')

/*
const components = {
    'base-button': 'atoms/base-button/BaseButton',
    'base-link': 'atoms/base-link/BaseLink',
    'base-input': 'molecules/base-input/BaseInput',
}
*/

Vue.use(vueCustomElement)
    // eslint-disable-next-line no-console

for (const tag in components){
  Vue.customElement(tag, () => new Promise((resolve) => {
    import( /* webpackMode: "lazy" */ `../src/${components[tag]}.vue`)
      .then(({default: component}) => {
        return resolve(component)
      })
  })
  )
}

//Vue.config.productionTip = false

