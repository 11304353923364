module.exports = {
    'base-button': 'atoms/BaseButton',
    'base-heading': 'atoms/BaseHeading',
    'base-table': 'organisms/BaseTable',
    'base-paragraph': 'atoms/BaseParagraph',
    'base-accordion': 'molecules/BaseAccordion',
    'base-accordion-nav': 'molecules/BaseAccordionNav',
    'base-card': 'molecules/BaseCard',
    'base-columns': 'molecules/BaseColumns',
    'base-column': 'molecules/BaseColumn',
    'base-hero': 'organisms/BaseHero',
    'base-highlight-card': 'organisms/BaseHighlightCard',
    'base-code-snippet': 'organisms/BaseCodeSnippet',
    'base-link': 'atoms/BaseLink',
    'base-icon': 'atoms/BaseIcon',
    'base-image': 'molecules/BaseImage',
    'base-input': 'molecules/BaseInput',
    'back-to-top': 'molecules/BackToTop',
    'base-textarea': 'molecules/BaseTextarea',
    'base-thumbnail': 'molecules/BaseThumbnail',
    'base-select': 'molecules/BaseSelect',
    'base-carousel': 'organisms/BaseCarousel',
    'base-nav-pagination': 'organisms/BaseNavPagination',
    'base-pagination': 'organisms/BasePagination',
    'base-footer': 'organisms/BaseFooter',
    'gallery-images': 'organisms/GalleryImages',
    'base-filter-select': 'molecules/BaseFilterSelect',
    'base-checkbox': 'molecules/BaseCheckbox',
    'base-radio': 'molecules/BaseRadio',
    'base-alert': 'molecules/BaseAlert',
    'base-list': 'molecules/BaseList',
}
